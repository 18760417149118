(function () {
    angular.module('ClefVerteModule').controller('ContactFormCtrl', ['$scope', '$timeout', '$http', function ($scope, $timeout, $http) {
        $scope.isLoading = false;
        $scope.recaptcha = null;
        $scope.sent = '';
        $scope.clientId = 0;
        $scope.gaTag = '';

        $scope.sendInfos = {
            email: '',
            message: ''
        };

        $scope.sendMail = function () {
            if ($scope.form.$valid) {
                $scope.isLoading = true;
                $scope.sendInfos.establishmentId = $scope.clientId;

                ga( 'send', 'event', 'Fiche établissement', 'Contact', $scope.gaTag );

                $http.post('/Umbraco/Api/SendMailApi/SendMail', $scope.sendInfos, {
                    headers: {
                        'g-recaptcha-response': $scope.recaptcha
                    }
                }).then(function (r) {
                    $scope.isLoading = false;
                    $scope.sent = 'OK';
                }, function (r) {
                    console.error("Error", r);
                    $scope.isLoading = false;
                    $scope.sent = 'ERR';
                });
            }
        }
    }]);
})();
