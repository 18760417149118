(function () {
    angular.module('ClefVerteModule').controller('SearchBarCtrl', ['$scope', '$timeout', '$http', '$window', function ($scope, $timeout, $http, $window) {
        var defaultTypeaheadPlaceholder = "Saisissez un département ou une région...";

        $scope.moreCollapsed = true;
        $scope.showTypeahead = false;
        $scope.shouldTypeaheadStayOpened = false;
        $scope.loadingLocations = false;
        $scope.noResults = false;

        $scope.modelOptions = {
            debounce: {
                default: 500,
                blur: 250
            }
        };

        $scope.types = [
            { id: '0', name: "Campings" },
            { id: '1', name: "Hôtels" },
            { id: '2', name: "Gîtes" },
            { id: '3', name: "Chambres d'hôtes" },
            { id: '4', name: "Auberges de jeunesse" },
            { id: '5', name: "Résidences de tourisme" },
            { id: '6', name: "Restaurants" },
            { id: '7', name: "Villages et centres de vacances" }
        ];

        $scope.destinations = [
            { id: 1, name: "Campagne" },
            { id: 2, name: "Mer" },
            { id: 4, name: "Montagne" },
            { id: 8, name: "Ville" }
        ];

        $scope.selected = {
            types: [],
            destinations: [],
            locations: [],
            chains: []
        };

        //init selected
        var search = decodeURIComponent( $window.location.search )
            .split(/[&||?]/)
            .filter(function (x) { return x.indexOf("=") > -1; })
            .map(function (x) { return x.split(/=/); })
            .map(function (x) {
                x[1] = x[1].replace(/\+/g, " ");
                return x;
            })
            .reduce(function (acc, current) {
                if (acc[current[0]])
                {
                    if (angular.isArray(acc[current[0]])) acc[current[0]].push(current[1]);
                    else acc[current[0]] = [acc[current[0]], current[1]];
                }
                else {
                    acc[current[0]] = current[1];
                }
                return acc;
            }, {});

        $scope.isNaturist = !!search.naturist;
        $scope.hasConferenceRoom = !!search.hasConferenceRoom;

        $scope.moreCollapsed &= !$scope.isNaturist;
        $scope.moreCollapsed &= !$scope.hasConferenceRoom;

        $scope.selected.types = _.filter($scope.types, function (x) {
            return _.some(search['types[]'], function (y) {
                return y == x.id;
            });
        });
        $scope.selected.destinations = _.filter($scope.destinations, function (x) {
            return _.some(search['destinations[]'], function (y) {
                return y == x.id;
            });
        });

        var locations = search['locations[]'] || [];
        $scope.selected.locations = angular.isArray(locations) ? locations : [locations];

        $http.get(CV.BackOfficeUrl + 'GetChains')
            .then(function success(response) {
                $scope.chains = response.data;
                $scope.selected.chains = _.filter($scope.chains, function (x) {
                    return _.some(search['chains[]'], function (y) {
                        return y == x.id;
                    });
                });

                $scope.moreCollapsed &= !($scope.selected.chains.length > 0);
            });

        $scope.submit = function () {
            var selected = {
                types: _.map($scope.selected.types, function (x) { return x.id }),
                destinations: _.map($scope.selected.destinations, function (x) { return x.id }),
                locations: _.map($scope.selected.locations, function (x) { return x }),
                chains: _.map($scope.selected.chains, function (x) { return x.id })
            }

            if ($scope.isNaturist) selected.naturist = $scope.isNaturist;
            if ($scope.hasConferenceRoom) selected.hasConferenceRoom = $scope.hasConferenceRoom;

            $(location).attr('href', '/recherche/?' + $.param(selected));
        };

        $scope.toggleType = function (type) {
            toggle('types', type);
        }

        $scope.toggleDestination = function (dest) {
            toggle('destinations', dest);
        }

        $scope.toggleChain = function (c) {
            toggle('chains', c);
        }

        $scope.toggleLocation = function (loc) {
            toggle('locations', loc);

            if ($scope.selected.locations.length > 0) $scope.shouldTypeaheadStayOpened = true;
            else $scope.shouldTypeaheadStayOpened = false;
        }

        $scope.isTypeSelected = function (type) {
            return $scope.selected.types.indexOf(type) > -1;
        }

        $scope.isDestinationSelected = function (dest) {
            return $scope.selected.destinations.indexOf(dest) > -1;
        }

        $scope.isChainSelected = function (c) {
            return $scope.selected.chains.indexOf(c) > -1;
        }

        $scope.getLocations = function (txt) {
            return $http.get(CV.BackOfficeUrl + 'GetTypeaheadOptions?query=' + encodeURIComponent(txt)).then(function succes(response) {
                return response.data;
            });
        }

        $scope.onTypeaheadSelect = function ($item, $model, $label, $event) {
            if ($scope.selected.locations.indexOf($item) < 0) {
                $scope.selected.locations.push($item);
            }

            $scope.typeaheadQuery = '';
        }

        $scope.toggleTypeahead = function (value) {
            $timeout(function () {
                if (!$scope.showTypeahead) {
                    if ($scope.selected.locations.length > 0) {
                        $scope.showTypeahead = value !== undefined ? value : true;
                    }
                }
                else {
                    if (!$scope.shouldTypeaheadStayOpened) {
                        $scope.showTypeahead = value !== undefined ? value : false;
                    }
                    else {
                        $scope.shouldTypeaheadStayOpened = false;
                    }
                }
            }, 100);
        }

        $scope.getTypeaheadPlaceholder = function () {
            return $scope.selected.locations.length > 0 ?
                computeTypeaheadPlaceholder() : defaultTypeaheadPlaceholder;
        }

        function computeTypeaheadPlaceholder() {
            var nbr = $scope.selected.locations.length;

            return nbr + ' sélectionné' + (nbr > 1 ? 's' : '');
        }

        function toggle(field, el) {
            var idx = $scope.selected[field].indexOf(el);

            if (idx > -1) {
                $scope.selected[field].splice(idx, 1);
            }
            else {
                $scope.selected[field].push(el);
            }
        }
    }]);
})();
