(function () {
    angular.module('ClefVerteModule').controller('SearchResultCtrl', ['$scope', '$timeout', '$cookies', '$window', function ($scope, $timeout, $cookies, $window) {
        $scope.leftColExpanded = true;
        $scope.rightColExpanded = true;
        $scope.mobileView = false;
        $scope.oldSizeBeforeResize = null;
        $scope.noResults = ClefVerte.MapItems.length == 0;

        var map = null;
        var openedInfoWindow = null;
        var hasQuery = $window.location.search != '' && ClefVerte.MapItems.length > 0;

        $scope.toggleMode = function (m, dontApplyLayout, dontScroll) {
            if (m == 'left') {
                $scope.leftColExpanded = true;
                $scope.rightColExpanded = false;
            }
            else if (m == 'right') {
                $scope.leftColExpanded = false;
                $scope.rightColExpanded = true;
            }
            else if (m == 'both') {
                $scope.leftColExpanded = true;
                $scope.rightColExpanded = true;
            }

            if (!dontApplyLayout) {
                handleLayout(dontScroll);
            }

            $cookies.put('searchModePreference', m);
        }

        $scope.getMode = function () {
            if ($scope.leftColExpanded && !$scope.rightColExpanded) return "left";
            else if (!$scope.leftColExpanded && $scope.rightColExpanded) return "right";
            else return "both";
        }

        function getWindowSize() {
            return ClefVerte.ResponsiveUtilities.getSize();
        }

        function scrollToResults() {
            var colOptionsTop = $('#col-options').offset().top;

            $('html, body').animate({
                scrollTop: colOptionsTop
            }, 300);
        }

        function handleLayout(dontScroll) {
            var colOptions = $('#col-options');
            var colOptionsHeight = colOptions.outerHeight(true);
            var windowHeight = window.innerHeight;
            var mapSize = $scope.getMode() == 'both' ? windowHeight : windowHeight - colOptionsHeight;
            var mapTop = $scope.getMode() == 'both' ? 0 : colOptionsHeight;
            
            var mapEl = $('#col-map');

            mapEl.height(mapSize);

            mapEl.css({
                'top': mapTop
            });

            setTimeout(function () {
                refreshMap();
            }, 600);

            if (!dontScroll) scrollToResults();
        }

        function handleMobileView() {
            $scope.$apply(function () {
                var currentSize = getWindowSize();

                if (currentSize < '2-MD') {
                    if ($scope.getMode() == 'both') {
                        $scope.toggleMode('left', true, true);
                        $scope.oldSizeBeforeResize = 'both';
                    }
                    $scope.mobileView = true;
                }
                else {
                    $scope.mobileView = false;
                    if ($scope.oldSizeBeforeResize) {
                        $scope.toggleMode($scope.oldSizeBeforeResize);
                        $scope.oldSizeBeforeResize = null;
                    }
                }
            });
        }

        function refreshMap() {
            var c = map.getCenter();
            google.maps.event.trigger(map, 'resize');
            map.setCenter(c);
        }

        function initMap() {
            var myLatLng = { lat: 47.596624, lng: 2.609412 }; //Center of France

            // Create a map object and specify the DOM element for display.
            map = new google.maps.Map(document.getElementById('map-frame'), {
                scrollwheel: false,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_CENTER
                },
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                }
            });

            map.setZoom(6);
            map.setCenter(myLatLng);

            var bounds = new google.maps.LatLngBounds();

            var items = ClefVerte.MapItems;

            $.each(items, function (index, m) {
                // Create a marker and set its position.
                var marker = new google.maps.Marker({
                    map: map,
                    position: { lat: m.Latitude, lng: m.Longitude },
                    title: m.Name,
                    icon: '/assets/img/markers/' + m.TypeCode + '.png'
                });

                //extend the bounds to include each marker's position
                bounds.extend(marker.position);

                var contentString = '<div id="client-map-desc">';
                {
                    if (m.Photo) {
                        contentString += '<div class="photo"><a href="/etablissement/' + m.ClientId + '" target="_blank"><img src="' + m.Photo + '" /></a></div>';
                    }

                    contentString += '<div class="desc">';
                    {
                        contentString += '<div class="name"><a href="/etablissement/' + m.ClientId + '" target="_blank">' + m.Name + '</a></div>';
                        contentString += '<div class="city"><i class="fa fa-map-marker"></i> ' + m.City + '</div>';

                        if (m.Email || m.Phone || m.Fax || m.Website) {
                            contentString += '<div class="contact">';
                            {
                                contentString += '<ul>';
                                {
                                    if (m.Website) contentString += '<li class="website"><a href="' + m.Website + '" target="_blank"><i class="fa fa-external-link"></i> ' + m.Website + '</a></li>';
                                    if (m.Email) contentString += '<li class="email"><a href="mailto:' + m.Email + '"><i class="fa fa-envelope-o"></i> ' + m.Email + '</a></li>';
                                    if (m.Phone) contentString += '<li class="phone"><i class="fa fa-phone"></i> ' + m.Phone + '</li>';
                                    if (m.Fax) contentString += '<li class="fax"><i class="fa fa-fax"></i> ' + m.Fax + '</li>';
                                }
                                contentString += '</ul>';

                            }
                            contentString += '</div>';
                        }
                    }
                    contentString += '</div>';

                    contentString += '<div class="clearfix"></div>'
                }
                contentString += '</div>';

                var infowindow = new google.maps.InfoWindow({
                    content: contentString
                });

                (function (marker, infowindow) {
                    marker.addListener('click', function () {
                        if (openedInfoWindow)openedInfoWindow.close();
                        openedInfoWindow = infowindow;
                        infowindow.open(marker.get('map'), marker);
                    });
                })(marker, infowindow);
            });

            google.maps.event.addDomListener(window, "resize", function () {
                var center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center);
            });

            google.maps.event.addListenerOnce(map, 'idle', function () {
                if( hasQuery )
                {
                    map.fitBounds(bounds);
                }
            });
        }

        $(function () {
            if (!$scope.noResults) {
                $('#col-map').affix({
                    offset: {
                        top: $('#col-map').offset().top,
                        bottom: $('#footer').outerHeight(true) + 40
                    }
                });

                initMap();

                // Apply saved search mode
                var initialSearchMode = $cookies.get('searchModePreference');

                if (initialSearchMode) {
                    $scope.toggleMode(initialSearchMode, true, true);
                }

                handleMobileView();
                handleLayout(!hasQuery);

                $(window).on('resize', function () {
                    handleMobileView();
                    handleLayout(true);
                });
            }
        });
    }]);
})();
