var ClefVerte = ClefVerte || {};

var Template = function () {
    function preventJumpingHash() {
        $('a[href="#"]').click(function (e) {
            e.preventDefault();
        });
    }

    function enableBackgroundImages() {
        var els = $("[data-bg-img]");
        if (els.length) els.each(function (i) {
            var el = $(this);
            var path = el.data('bgImg');
            var align = el.data('bgAlign');

            if (align) el.css('background-position', align);
            el.css('background-image', "url('" + path + "')");
        });
    }

    $(function () {
        preventJumpingHash();
        enableBackgroundImages();

        $.cookieBar({
            message: "En poursuivant votre navigation sur ce site, vous acceptez nos conditions d'utilisation et l'utilisation de cookies afin de vous proposer un contenu adapté et l'utilisation des réseaux sociaux.",
            acceptButton: "Je comprends",
            bottom: true,
            acceptOnContinue: true,
            fixed: true,
            zindex: '999999',
            acceptText: "J'accepte",
            policyButton: true,
            policyText: "Plus d'informations",
            policyURL: '/mentions-légales',
            expireDays: 365
        });
    });
}

ClefVerte.Template = new Template();