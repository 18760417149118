(function () {
    angular.module('ClefVerteModule')
    .directive('hiddenEmail', function () {
        return {
            template: '<a href="#" ng-click="showText()" ng-if="!displayText">Cliquez ici pour afficher le texte</a> <a href="mailto:{{ displayText }}" ng-if="displayText">{{ displayText }}</a> ',
            restrict: 'E',
            scope: {
                encodedText: '@'// Text is encoded in base64 in order to prevent spam actions
            },
            link: function (scope, element, attrs) {
                var decodedText = atob(scope.encodedText);

                scope.showText = function () {
                    scope.displayText = decodedText;
                }
            }
        }
    });
})();
